import {apiDelete, apiGet} from "./apiService";
import {SelfAccount} from "@/types";

export async function getAccount(userToken: string): Promise<SelfAccount> {
    const profileResponse = await apiGet('/self/account', userToken);

    if (profileResponse.ok) {
        return profileResponse.json();
    }

    console.error(profileResponse);

    throw new Error('Failed to get profile');
}

export async function deleteAccount(uuid: string, userToken: string) {
    try {
        const profileResponse = await apiDelete(`/admin/account/${uuid}`, userToken);

        if (profileResponse.ok) {
            return true;
        }
    } catch (error) {
        console.error(error);
        return false;
    }
}

export async function getProfileGallery(accountUuid: string, userToken: string) {
    const profileResponse = await apiGet(`/public/account/${accountUuid}`, userToken);

    if (profileResponse.ok) {
        const {username, galleries} = await profileResponse.json();

        const gallery = galleries[0];

        if (!gallery) return;

        const galleryResponse = await apiGet(`/public/${username}/gallery/${gallery.uuid}`, userToken);

        if (!galleryResponse.ok) {
            throw new Error('Shit went bananas, doh!');
        }

        return galleryResponse.json();
    }

    return false;
}

export async function getProfileGalleries(accountUuid, authToken) {
    if (!accountUuid || !authToken) return false;

    const galleryResponse = await apiGet(`/public/account/${accountUuid}/gallery`, authToken);

    if (galleryResponse.ok) {
        const galleries = await galleryResponse.json();

        if (galleries?.error) {
            throw new Error(galleries.error);
        }

        if (galleries) {
            return galleries;
        }
    }

    console.log(galleryResponse.status, galleryResponse.statusText);

    return false;
}