export default function hostnameAsSubDomain() {
    const hostNameParts = window.location.hostname.split('.');

    if (hostNameParts.length === 1) {
       return hostNameParts[0];
    }

    const extension = hostNameParts.pop();

    const domainName = hostNameParts.pop();

    const addDot = process.env.REACT_APP_CONFIG_ENV === 'production' ? '.' : '';

    return `${addDot}${domainName}.${extension}`;
}