import config from "../config";

export default function imagePathFromFile(file?: any | string): string {
    if (!file) {
        return '';
    }

    if (typeof file === 'string') {
        const url = new URL(config.apiBasePath, config.apiUrl);
        return `${url.href.replace(/\/$/, '')}/${file.replace(/^\//, '')}`;
    }

    if (file.hasOwnProperty('src')) {
        return file.src;
    }

    if (!file?.filePathOrUrl) {
        throw new Error('Invalid file type');
    }

    switch(file?.sourceType) {
        case 'file':
        case 'legacy-file':
            const url = new URL(config.apiBasePath, config.apiUrl);
            return `${url.href.replace(/\/$/, '')}/${file.filePathOrUrl.replace(/^\//, '')}`;
        case 'url':
            return file.filePathOrUrl;
        default:
            return '';
    }
}