import {SetStateAction} from "react";
import {ChatEntryInterface} from "../Chat";

export default function userDisconnectedHandler(setMessages: SetStateAction<any>, autoScrollHandler: () => void) {
    return (event: Event) => {
        const {detail: username} = event as CustomEvent;

        const message = `User (${username}) disconnected`;

        setMessages((currentMessages: ChatEntryInterface[]) => ([...currentMessages, {message, messageType: 'message', senderUsername: username}]))

        autoScrollHandler();
    };
}