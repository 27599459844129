import {SetStateAction} from "react";

export default function userConnectedHandler(setMessages: SetStateAction<any>, autoScrollHandler: () => void) {
    return (event: Event) => {
        const {detail: user} = event as CustomEvent;

        const {username} = user;

        const message = `User (${username}) connected`;

        setMessages((currentMessages: Element[]) => ([...currentMessages, {message, messageType: 'message', senderUsername: username}]))

        autoScrollHandler();
    };
}