export function unscrambleEmail(scrambledEmail: string = '') {
    const reversedEmail = scrambledEmail.split('').reverse().join('');

    return new Buffer(reversedEmail, 'base64').toString('ascii');
}

export function scrambleEmail(email: string = '') {
    const base64Email = new Buffer(email).toString('base64');

    return base64Email.split('').reverse().join('');
}