import AvatarEditor from "react-avatar-editor";
import {FiRotateCw, FiZoomIn, FiZoomOut} from "react-icons/fi";
import React, {useRef, useState} from "react";
import {Button} from "@varymadellc/my-weed-club-component-library";
import {ButtonVariantProps} from "@varymadellc/my-weed-club-component-library";

interface AvatarEditSectionProps {
    image: any;
    onReset: () => void;
    onUpload: (image: any, name: string) => () => Promise<void>;
    setIsLoading: (key: boolean) => void;
    rounded?: boolean;
}

export default function ImageUploadEditor({
                                              image: imagePayload,
                                              onReset,
                                              onUpload,
                                              setIsLoading,
                                              rounded = true
                                          }: AvatarEditSectionProps) {
    const editorRef = useRef<any>();

    const [zoomLevel, setZoomLevel] = useState<number>(1);

    const [rotate, setRotate] = useState<number>(0);

    const [newImage, setNewImage] = useState<any>();

    const onResetLocal = () => {
        setNewImage(null);

        onReset();
    }

    const zoomIn = () => {
        setIsLoading(true);

        setZoomLevel(zoomLevel < 3 ? zoomLevel + .1 : zoomLevel);
    }

    const zoomOut = () => {
        setIsLoading(true);

        setZoomLevel(zoomLevel > .5 ? zoomLevel - .1 : zoomLevel);
    }

    const rotate90 = () => {
        setRotate(rotate + 90);
    }

    return (
        <div className="flex flex-col p-2">
            <div className="justify-center flex p-2">
                {imagePayload && <AvatarEditor
                    ref={editorRef}
                    image={imagePayload.image}
                    width={200}
                    height={200}
                    border={10}
                    borderRadius={rounded ? 100 : 0}
                    color={[255, 255, 255, 0.8]} // RGBA
                    scale={zoomLevel}
                    rotate={rotate}
                    onImageReady={() => {
                        setIsLoading(false);
                    }}
                    onImageChange={async () => {
                        try {
                            setIsLoading(true);

                            const canvas = editorRef?.current;

                            const image = canvas.getImage();

                            const dataUrl = image.toDataURL();

                            setNewImage(dataUrl);

                            setIsLoading(false);
                        } catch (error: any) {
                            console.debug(error.message);
                            setIsLoading(false);
                        }
                    }}
                />}
            </div>
            <div className="flex flex-row">
                <div className="flex flex-1">
                    <Button onClick={zoomIn} type="button"><FiZoomIn/></Button>
                    <Button onClick={zoomOut} type="button"><FiZoomOut/></Button>
                    <Button onClick={rotate90} type="button"><FiRotateCw/></Button>
                </div>
                <div className="justify-end flex-1">
                    {imagePayload &&
                    <Button variant={'link' as ButtonVariantProps.link} type="reset" onClick={onResetLocal}>Reset</Button>}
                    {imagePayload && <Button type="button"
                                             onClick={onUpload(newImage || imagePayload.image, imagePayload.name)}>Upload</Button>}
                </div>
            </div>
        </div>
    );
}