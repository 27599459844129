import React, {useEffect, useState} from "react";
import {useAuth} from ".//AuthProvider";
import {getAccount} from "../services/profileService";
import {useSiteMessage} from "./SiteMessageProvider";
import {SelfAccount} from "@/types";

export const ProfileContext = React.createContext<{
    userAccount: never | SelfAccount;
    setUserAccount: (userAccount: never | SelfAccount) => void;
}>(null!);

export function useUserAccountProvider() {
    return React.useContext(ProfileContext);
}

export default function UserAccountProvider({ children }: { children: React.ReactNode }) {
    const {setMessage} = useSiteMessage();

    const {authToken} = useAuth();

    const [userAccount, setUserAccount] = useState<SelfAccount>(null! as SelfAccount);

    useEffect(() => {
        (async () => {
            try {
                if (authToken && !userAccount) {
                    const userAccount = await getAccount(authToken);

                    if (!userAccount) {
                        throw new Error('User account not found, please log back in. You can log out vai the footer nav.');
                    }

                    if (!userAccount?.metadata) {
                        userAccount.metadata = {};
                    }

                    setUserAccount(userAccount);
                }
            } catch(error: any) {
                setMessage(error.message);
            }
        })();
    }, [authToken, setMessage])

    return <ProfileContext.Provider value={{userAccount, setUserAccount}}>{children}</ProfileContext.Provider>;
}
