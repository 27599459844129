import cx from "classnames";
import {useEffect, useState} from "react";

export interface HealthIndicatorProps {
    isConnected: boolean;
}

export default function HealthIndicator({isConnected}: HealthIndicatorProps) {
    return (
        <div className={cx('inline-block border-4 border-green-700 rounded-full w-[20px] h-[20px]', {'bg-green-500': isConnected}, {'border-red-900': !isConnected, 'bg-red-500': !isConnected})}>&nbsp;</div>
    );
}