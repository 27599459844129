export default function isValidToken(authToken: string) {
    const jwtPayloadDecodedObject = getTokenParts(authToken);

    const jwtPayloadDecodedObjectExp = jwtPayloadDecodedObject?.exp || 0;

    const jwtPayloadDecodedObjectExpDate = new Date(jwtPayloadDecodedObjectExp * 1000);

    const jwtPayloadDecodedObjectExpDateNow = new Date();

    return jwtPayloadDecodedObjectExpDateNow < jwtPayloadDecodedObjectExpDate
}

export function getTokenParts(authToken: string) {
    const jwtParts = authToken?.split('.');

    const jwtPayload = jwtParts?.[1];

    const jwtPayloadDecoded = jwtPayload ? atob(jwtPayload) : '';

    return jwtPayloadDecoded ? JSON.parse(jwtPayloadDecoded) : {};
}