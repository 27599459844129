import React from "react";
import {socket, autoConnect} from '../services/socketService';
import {Socket} from "socket.io-client";

export interface ChatSocketContextInterface {
    connectSocket: () => boolean;
    disconnectSocket: () => boolean;
    socket: Socket;
    autoConnect: boolean;
    isConnected: () => boolean;
}

const connectSocket = () => {
    console.log('CONNECTED SOCKET');
    socket.connect();

    return socket.connected;
}

const isConnected = () => {
    if (!socket) return false;

    return socket?.connected || false;
}

const disconnectSocket = () => {
    console.log('DISCONNECTED SOCKET');
    socket.disconnect();

    return socket.connected;
}

export const ChatSocketContext = React.createContext<ChatSocketContextInterface>({socket, autoConnect, connectSocket, disconnectSocket, isConnected});

export function useChatSocketProvider() {
    return React.useContext(ChatSocketContext);
}

export default function ChatSocketProvider({children}: { children: React.ReactNode }) {
    return <ChatSocketContext.Provider
        value={{socket, autoConnect, connectSocket, disconnectSocket, isConnected}}>{children}</ChatSocketContext.Provider>;
}
