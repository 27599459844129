import {Dispatch, SetStateAction} from "react";

export default function setImageOnUpload(setNewImage: Dispatch<SetStateAction<{name: string; image: string | ArrayBuffer | null;}>>) {
    return (event: any) => {
        const file = event?.target?.files?.[0];

        if (!file) return;

        setNewImage({image: file, name: file.name});
    };
}