import audioFileReceivedHandler from "./audioFileReceivedHandler";
import activeUserCountHandler from "./activeUserCountHandler";
import connectErrorHandler from "./connectErrorHandler";
import userConnectedHandler from "./userConnectedHandler";
import disconnectHandler from "./disconnectHandler";
import connectHandler from "./connectHandler";
import userUsernameHandler from "./userUsernameHandler";
import userMessageHandler from "./userMessageHandler";
import userDisconnectedHandler from "./userDisconnectedHandler";
import {Dispatch, SetStateAction} from "react";
import chatImageReceivedHandler from "./chatImageReceivedHandler";
import initialMessageStateReceivedHandler from "./initialMessageStateReceivedHandler";

export interface mountSocketEventHandlerProps {
    setMessages: Dispatch<SetStateAction<any>>;
    setActiveUserCount: Dispatch<SetStateAction<number>>;
    setIsConnected: Dispatch<SetStateAction<boolean>>;
    autoScrollHandler: () => void;
    setChatUserUsername: Dispatch<SetStateAction<string>>;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
}

export default function mountSocketEventHandler({setMessages, setActiveUserCount, setIsConnected, autoScrollHandler, setChatUserUsername, setIsLoading}: mountSocketEventHandlerProps) {
    const eventListenerHandlers = {
        chat_image_received: chatImageReceivedHandler(setMessages, autoScrollHandler),
        audio_file_received: audioFileReceivedHandler(setMessages, autoScrollHandler),
        initial_message_state_received: initialMessageStateReceivedHandler(setMessages, autoScrollHandler, setIsLoading),
        active_user_count: activeUserCountHandler(setActiveUserCount),
        disconnect: disconnectHandler(setIsConnected),
        connect: connectHandler(autoScrollHandler),
        connect_error: connectErrorHandler(setIsConnected),
        user_connected: userConnectedHandler(setMessages, autoScrollHandler),
        user_username: userUsernameHandler(setChatUserUsername),
        user_message: userMessageHandler(setMessages, autoScrollHandler),
        user_disconnected: userDisconnectedHandler(setMessages, autoScrollHandler),
    };

    for (const name in eventListenerHandlers) {
        // @ts-ignore
        const handler = eventListenerHandlers[name] as (event: Event) => void;

        window.addEventListener(name, handler);
    }

    return () => {
        for (const name in eventListenerHandlers) {
            // @ts-ignore
            const handler = eventListenerHandlers[name] as (event: Event) => void;

            window.removeEventListener(name, handler);
        }
    }
}