import {forwardRef, ReactElement} from "react";
import cx from "classnames";

export interface ChatMessagesProps {
    children: ReactElement | ReactElement[];
    enableScrolling?: boolean;
    className?: string;
}

function ChatMessages({children, className}: ChatMessagesProps, ref) {
    return (
       <div ref={ref} className={cx("text-xl bg-gray-700 border-4 border-black text-white bg-black h-full min-h-[200px] max-h-full overflow-x-hidden overflow-y-scroll", className)}>
           {children}
       </div>
    );
}

export default forwardRef(ChatMessages);