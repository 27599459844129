import React, {ChangeEvent} from "react";
import {Button, Card, Input} from "@varymadellc/my-weed-club-component-library";
import cx from "classnames";

export interface MessageBarProps {
    onSubmit: (event: any) => void;
    disabled?: boolean;
    value: string;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
    className?: string;
}

export default function MessageBar(props: MessageBarProps) {
    const {onSubmit, value, onChange, className, disabled} = props;
    const checkForSubmission = (event: any) => {
        if (event.metaKey && event.keyCode === 13) {
            onSubmit(event);
        }
    }

    return (
        <Card id="messageBar" className={cx("flex flex-col align-middle bg-dark-black p-2", className)}>
            <form className="block relative" onKeyDown={checkForSubmission}
                  onSubmit={(event) => event.preventDefault()}>
                <div className="flex flex-row align-middle">
                    <Input className="flex-1 flex-initial w-9/12 flex-1 relative rounded-tr-none rounded-br-none h-16 m-0 z-0"
                           placeholder="Type a message..."
                           value={value}
                           onChange={onChange}
                           id="input"
                           disabled={disabled}
                           autoComplete="off"/>
                    <Button onClick={onSubmit}
                            disabled={disabled}
                            className="flex-1 -min-w-80 w-3/12 relative rounded-tl-none rounded-bl-none h-16 m-0 z-1"
                            type="button">Send</Button>
                </div>
                <small className="hidden sm:inline">cmd + enter to submit message. Or use send button.</small>
            </form>
        </Card>
    );
}
