import React, {ReactElement, Suspense, useState} from "react";
import {NextComponentType, NextPageContext} from "next";
import Chat from "@/components/Chat/Chat";
import Loading from "@/components/Loading/Loading";
import ChatMount from "@/layout/ChatMount";
import {useAuth} from "@/providers/AuthProvider";
import DefaultLayout from "@/layout/DefaultLayout/DefaultLayout";

export interface SetupLayoutProps {
    component: NextComponentType<NextPageContext, any, any>;
    pageProps: any;
    showHeader?: boolean;
    showFooter?: boolean;
    allowFullScreen?: boolean;
    Link: any;
}

export default function SetupLayout({
                                        component,
                                        pageProps,
                                        showHeader = true,
                                        showFooter = true,
                                        allowFullScreen = false,
                                        Link
                                    }: SetupLayoutProps): ReactElement {
    const {isAdmin, authToken} = useAuth();

    const Component = component as unknown as any;

    return (
        <DefaultLayout allowFullScreen={allowFullScreen} isAdmin={isAdmin} showHeader={showHeader} showFooter={showFooter} showFooterNav={Boolean(authToken)} LinkAs={Link}>
            <Component {...pageProps} />
        </DefaultLayout>
    );
}