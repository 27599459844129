import {AUTH_COOKIE} from "../CONSTANTS";
import cookies from 'js-cookie';
import {apiPost} from "./apiService";
import hostnameAsSubDomain from "../utils/hostnameAsSubDomain";

export interface loginProps {
    email: string;
    password: string;
}

export async function login(credentials: loginProps): Promise<[error?: string, payload?: any]> {
    try {
        const response = await apiPost('/login', JSON.stringify(credentials, null, 2))

        let payload;

        let error;

        if (response.ok) {
            const json = await response.json()

            if (json?.error) {
                throw new Error(json.error);
            }

            const domain = hostnameAsSubDomain();

            cookies.remove(AUTH_COOKIE, {domain});

            cookies.set(AUTH_COOKIE, json.token, {domain, expires: 7});

            cookies.get(AUTH_COOKIE);

            if (json) {
                payload = json;
            } else {
                error = 'system login failure, we will be back up shortly. Email dechamp@varymade.com if you have concerns.';
            }
        }

        if (!response.ok) {
            error = response.statusText;
        }

        return [error, payload];
    } catch(error: any) {
        console.log(error);
        return [`${error}`];
    }
}