import React from "react";

export interface HomeNavProps {
    LinkAs: any;
}

export default function HomeNav({LinkAs}: HomeNavProps) {
   return (
       <div className="flex flex-col w-full p-10 animate-top items-end gap-4">
           <LinkAs href="/signup#signupForm" className="text-black underline">Sign Up</LinkAs>
           <LinkAs href="/#signinForm" className="text-black underline">Sign In</LinkAs>
           <LinkAs href="/blog" className="text-black underline">Blog</LinkAs>
       </div>
   );
}