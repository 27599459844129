import {ChatEntryInterface} from "../../components/Chat/Chat";
import {ReactElement} from "react";
import ChatEntry from "../ChatEntry/ChatEntry";

export interface ChatMessageProps {
    chatEntry?: ChatEntryInterface;
    children?: ReactElement | string;
    [key: string]: any;
}

export default function ChatMessage({children, chatEntry, _ref, ...rest}: ChatMessageProps) {
    let chatEntrySection;

    if (chatEntry) {
        chatEntrySection = <ChatEntry chatEntry={chatEntry}/>;
    }

    return (
        <div ref={_ref} className="border-0" {...rest}>
            {chatEntrySection}
            {children}
        </div>
    );
}