import {ReactNode} from "react";
import cx from "classnames";

interface BodyProps {
    children: ReactNode;
    className?: string;
}

export default function Body({children, className}: BodyProps) {
    return (
        <div className={cx('relative overflow-x-hidden', className)}>
            {children}
        </div>
    );
}