import {ChatEntryInterface} from "../../components/Chat/Chat";
import imagePathFromFile from "../../utils/imagePathFromFile";
import {useState} from "react";

interface ChatMessageTypeImageProps {
    chatEntry: ChatEntryInterface;
}

export default function ChatMessageTypeImage({chatEntry}: ChatMessageTypeImageProps) {
    const [isImageLoaded, setIsImageLoaded] = useState(true);

    const {message} = chatEntry;

    return (
        isImageLoaded ? <img onError={() => setIsImageLoaded(false)} width="500px" height="auto" src={imagePathFromFile(message)} alt={message} /> : null
    );
}