import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import {apiGet, apiPost, extractPayloadFromResponse} from "@/services/apiService";
import {useAuth} from "@/providers/AuthProvider";

export const SiteSettingsContext = React.createContext<any>(null!);

interface useSiteSettingsProps {
    isMaintenanceMode?: boolean;
}

export function useSiteSettings(): [useSiteSettingsProps, Dispatch<SetStateAction<useSiteSettingsProps>>, () => Promise<void>] {
    return React.useContext(SiteSettingsContext);
}

const getSettings = async () => {
    const response = await apiGet('/admin/site-settings');

    const {data, error} = await extractPayloadFromResponse(response);

    if (!data) {
        throw new Error('Could not find any settings');
    }

    if (error) {
        throw new Error(error);
    }

    return data.reduce((acc, setting: any) => {
        acc[setting.key] = setting.payload;

        return acc;
    }, {});
}

const pushSettings = async (authToken, settings: Record<string, any>) => {
    const response = await apiPost('/admin/site-settings', JSON.stringify(settings), authToken);

    const {data, error} = await extractPayloadFromResponse(response);

    if (!data) {
        throw new Error('Could not find any settings');
    }

    if (error) {
        throw new Error(error);
    }

    return data.reduce((acc, setting: any) => {
        acc[setting.key] = setting.payload;

        return acc;
    }, {});
}

export default function SiteSettingsProvider({children}: { children: React.ReactNode }) {
    const [settings, setSettingsDispatch] = useState<useSiteSettingsProps>({});

    const {authToken} = useAuth();

    useEffect(() => {
        (async () => {
            try {
                const settingsByKeys = await getSettings();

                setSettingsDispatch(settingsByKeys);
            } catch (error: any) {
                console.error(error);
            }
        })();
    }, []);

    const setSettings = async (newSettings: useSiteSettingsProps) => {
        try {
            if (newSettings === settings) return;

            const updatedSettings = await pushSettings(authToken, newSettings);

            setSettingsDispatch(updatedSettings);
        } catch (error: any) {
            console.error(error);
        }
    }

    return <SiteSettingsContext.Provider value={[settings, setSettings]}>{children}</SiteSettingsContext.Provider>;
}
