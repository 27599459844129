import React, {ChangeEvent, useCallback, useEffect, useRef, useState} from "react";
import {useChatSocketProvider} from "../../providers/ChatSocketProvider";
import ChatMessagesWithAutoScroll from "./Messages";
import {socketInit} from "../../services/chatSocketService";
import {BsFillShieldLockFill} from "react-icons/bs";
import {HealthIndicator} from "../../components/HealthIndicator";
import ChatStats from "./ChatStats";
import MessageBar from "./MessageBar";
import mountSocketEventHandlers from "./eventHandler/mountSocketEventHandlers";
import Modal from "../../layout/Modal";
import ChatModal from "./ChatModal";
import {Button} from "@varymadellc/my-weed-club-component-library";

// TODO - the whole chat reloads on every typed letter in the chat input. Fix this.
export interface ChatEntryInterface {
    senderUsername: string;
    uuid: string;
    senderAccountUuid: string;
    messageType: string;
    message: string;
    createdAt: Date;
    updatedAt: Date;
    deletedAt: Date;
}

export default function Chat() {
    const messagesRef = useRef<any>(null!);

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [isChatStatsModalOpen, setIsChatStatsModalOpen] = useState<boolean>(false);

    const {socket, connectSocket, disconnectSocket} = useChatSocketProvider();

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const [input, setInput] = useState<string>('');

    const [activeUserCount, setActiveUserCount] = useState<number>(0);

    const [chatUserUsername, setChatUserUsername] = useState<string>('');

    const [isConnected, setIsConnected] = useState<boolean>(socket?.connected || false);

    const [autoScroll, setAutoScroll] = useState<boolean>(true);

    const [messages, setMessages] = useState<ChatEntryInterface[]>([]);

    const scrollToBottom = (bottomRef) => {
        bottomRef?.current && bottomRef.current.scrollIntoView({behavior: 'smooth'});

        setTimeout(() => {
            bottomRef?.current && bottomRef.current.scrollTo({left: 0, top: bottomRef.current.scrollHeight, behavior: 'smooth'});
        }, 200);
    };

    const autoScrollHandler = useCallback(() => {
        if (messagesRef?.current && autoScroll) {
            scrollToBottom(messagesRef);
        }
    }, [messagesRef.current, autoScroll]);

    const setIsConnectedHandler = useCallback((isConnected: boolean) => {
        if (isConnected) {
            gtag('event', 'chat_connect', {
                status: 'connected'
            });
            if (connectSocket()) {
                setIsConnected(true);
            }
        } else {
            gtag('event', 'chat_connect', {
                status: 'disconnected'
            });

            setActiveUserCount(0);

            if (disconnectSocket()) {
                setIsConnected(false);
            }
        }

        setMessages([]);
    }, [setMessages, disconnectSocket, connectSocket]);

    const loadChatDataHandler = () => {
        setIsLoading(true);
        socket.emit('initial_message_state');

        setTimeout(() => {
            setIsLoading(false);
        }, 5000);
    }

    useEffect(() => {
        return mountSocketEventHandlers({
            setMessages,
            setActiveUserCount,
            setIsConnected,
            autoScrollHandler,
            setChatUserUsername,
            setIsLoading,
        });
    }, []);

    useEffect(() => {
        return socketInit(socket);
    }, []);

    useEffect(() => {
        setIsConnected(socket?.connected || false);
    }, [socket?.connected]);

    useEffect(() => {
        const disconnectChat = () => {
            disconnectSocket();

            setIsConnected(false);
        }

        window.addEventListener('beforeunload', disconnectChat);

        return () => window.removeEventListener('beforeunload', disconnectChat);
    }, [setIsConnected, disconnectSocket]);

    const submitHandler = (event: any) => {
        event.preventDefault();

        if (input) {
            socket.emit('message', input);
            setInput('');
        }
    };

    const messageSubmitHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setInput(event.target.value);
    };

    return (
        <div className="relative flex flex-col h-[600px] p-2">
            <div className="flex-[1]">
                <div className="flex my-2">
                    <div className="flex flex-1 flex-row">
                        <HealthIndicator isConnected={isConnected}/>
                    </div>
                    {
                        isConnected &&
                        <div className="flex flex-[2] items-center">
                            <span className="underline text-sm"
                                  onClick={() => setIsChatStatsModalOpen(true)}>Chat Stats</span>
                        </div>
                    }
                    <div className="flex flex-[3] justify-end items-center">
                        <span className="text-sm">Secure Chat &nbsp; <BsFillShieldLockFill
                            className="inline-block"/></span>
                    </div>
                </div>
                <div className="flex flex-row gap-2 mb-2">
                    {isConnected &&
                        <Button className="flex-1 text-xs sm:text-base" type="button" onClick={loadChatDataHandler}>load
                            last 50 messages</Button>}
                    <Button className="flex-1 text-xs sm:text-base" type="button"
                            onClick={() => setIsConnectedHandler(!isConnected)}>{isConnected ? 'Disconnect' : 'Connect'}</Button>
                </div>
                {isConnected &&
                    <Button onClick={() => scrollToBottom(messagesRef)} className="bg-secondary w-full !p-0">Latest
                        Message</Button>}
            </div>
            <ChatMessagesWithAutoScroll className="h-full max-h-[350px]" ref={messagesRef} isLoading={isLoading}
                                            messages={messages}/>
            <div className="flex justify-end p-2 gap-2 bg-black !rounded-none">
                <Button onClick={() => setAutoScroll(!autoScroll)} className="flex-1 bg-secondary h-10"
                        type="submit"><span>AutoScroll:{autoScroll ? <span className="underline">on</span> :
                    <span className="underline">off</span>}</span></Button>
                {/*<Talk/> TODO reimplement voice */}
                {isConnected &&
                    <Button onClick={() => setIsModalOpen(!isModalOpen)} className="flex-1 bg-secondary"
                            type="button">Image
                    </Button>}
            </div>
            <MessageBar disabled={!isConnected} className="flex flex-1"
                        onSubmit={submitHandler} value={input}
                        onChange={messageSubmitHandler}/>
            <Modal isOpen={isChatStatsModalOpen} onClose={() => setIsChatStatsModalOpen(false)}>
                <div className="flex flex-col gap-2">
                    <ChatStats messages={messages} username={chatUserUsername} activeUsersCount={activeUserCount}/>
                </div>
            </Modal>
            <Modal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
            >
                <ChatModal setIsOpen={setIsModalOpen}/>
            </Modal>
        </div>
    );
}

export const getServersideProps = async () => {
    return {
        props: {
            title: 'Chat',
        }
    }
}