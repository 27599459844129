import {ChatEntryInterface} from "../../components/Chat/Chat";

interface ChatMessageTypeMessageProps {
    chatEntry: ChatEntryInterface;
}

export default function ChatMessageTypeMessage({chatEntry}: ChatMessageTypeMessageProps) {
    const {message} = chatEntry;

    return (
       <div className="text-white">{message}</div>
    );
}