import {useAuth} from "../providers/AuthProvider";
import Link from "next/link";
import {ReactElement, useEffect, useState} from "react";
import {useRouter} from "next/navigation";
import Loading from "@/components/Loading/Loading";

const allItemsTruthy = (items: any) => {
    return items.every((item: any) => {
        if (typeof item === 'function') {
            return Boolean(item());
        }

        return Boolean(item);
    });
}

export default function RequireAuth({
                                        children,
                                        authScreen,
                                        byPassIfTrue = undefined
                                    }: { children: JSX.Element, authScreen?: ReactElement, byPassIfTrue?: boolean | Array<boolean | (() => boolean)> }) {
    const [shouldByPass, setShouldByPass] = useState<boolean>(false);

    let {isValidToken} = useAuth();

    useEffect(() => {
        if (isValidToken()) {
            setShouldByPass(true);

            return;
        }

        if (Array.isArray(byPassIfTrue) && allItemsTruthy(byPassIfTrue)) {
            setShouldByPass(true);

            return;
        }

        if (byPassIfTrue === true) {
            setShouldByPass(true);

            return;
        }

        setShouldByPass(false);
    }, [byPassIfTrue, children, authScreen, isValidToken]);

    if (shouldByPass) {
        return children;
    }

    if (shouldByPass === false) {
        // TODO Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        if (authScreen) {
            return authScreen;
        }

        return (
            <div className="m-4">
                <p className="text-black">You must login, please return to the home page and login in. </p>
                <p className="text-black">Redirecting to home in 3 seconds</p>
                <Link href="/" className="text-black underline">Home</Link>
            </div>
        );
    }

    return <Loading fullScreen={true}/>
}