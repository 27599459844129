import {SetStateAction} from "react";
import {ChatEntryInterface} from "../Chat";

export default function userMessageHandler(setMessages: SetStateAction<any>, autoScrollHandler: () => void) {
    return (event: Event) => {
        const data = event as CustomEvent;

        const chatEntries = data.detail as ChatEntryInterface | ChatEntryInterface[];

        if (Array.isArray(chatEntries)) {
            setMessages((currentMessages: ChatEntryInterface[]) => [...currentMessages, ...chatEntries]);

            autoScrollHandler();

            return
        }

        setMessages((currentMessages: ChatEntryInterface[]) => [...currentMessages, chatEntries]);

        autoScrollHandler();
    };
}