import {ChatEntryInterface} from "../components/Chat/Chat";

const socketInit = (socket) => {
    socket.on('user_message', (chatEntry: ChatEntryInterface) => {
        window.dispatchEvent(new CustomEvent('user_message', {detail: chatEntry}));
    });

    socket.on('user_uuid', (uuid: string) => {
        window.dispatchEvent(new CustomEvent('user_uuid', {detail: uuid}));
    });

    socket.on('user_username', (username: string) => {
        window.dispatchEvent(new CustomEvent('user_username', {detail: username}));
    });

    socket.on('connect', () => {
        window.dispatchEvent(new Event('connect'));
    });

    socket.on('disconnect', () => {
        window.dispatchEvent(new Event('disconnect'));
    });

    socket.on('user_connected', (user) => {
        window.dispatchEvent(new CustomEvent('user_connected', {detail: user}));
    });

    socket.on('user_disconnected', (username: string) => {
        window.dispatchEvent(new CustomEvent('user_disconnected', {detail: username}));
    });

    socket.on("connect_error", (err) => {
        window.dispatchEvent(new CustomEvent('connect_error', {detail: err.message}));
        console.log(`connect_error due to ${err.message}`);
    });

    socket.on('active_user_count', (count) => {
        window.dispatchEvent(new CustomEvent('active_user_count', {detail: count}));
    });

    socket.on('talk', (message) => {
        window.dispatchEvent(new CustomEvent('talk', {detail: message}));
    });

    socket.on('audio_file_received', (filePath) => {
        window.dispatchEvent(new CustomEvent('audio_file_received', {detail: filePath}));
    });

    socket.on('chat_image_received', (detail) => {
        window.dispatchEvent(new CustomEvent('chat_image_received', {detail}));
    });

    socket.on('initial_message_state_received', (chatEntries) => {
        window.dispatchEvent(new CustomEvent('initial_message_state_received', {detail: {chatEntries}}));
    });

    return () => {
        socket.off('user_message');
        socket.off('user_uuid');
        socket.off('user_username');
        socket.off('connect');
        socket.off('disconnect');
        socket.off('user_connected');
        socket.off('user_disconnected');
        socket.off('connect_error');
        socket.off('active_user_count');
        socket.off('talk');
        socket.off('audio_file_received');
        socket.off('chat_image_received');
    };
};

export {
    socketInit,
}