import {ChatEntryInterface} from "../../components/Chat/Chat";
import imagePathFromFile from "../../utils/imagePathFromFile";

interface ChatMessageTypeAudioProps {
    chatEntry: ChatEntryInterface;
}

export default function ChatMessageTypeAudio({chatEntry}: ChatMessageTypeAudioProps) {
    const {message} = chatEntry;

    return (
       <audio src={imagePathFromFile(message)} controls={true} preload="metadata" />
    );
}