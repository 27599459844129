import React from "react";
import cx from "classnames";

export interface LoadingProps {
    hasOverlay?: boolean;
    className?: string;
    fullScreen?: boolean;
}

export default function Loading({hasOverlay, className, fullScreen = false}: LoadingProps) {
    return (
        <div className={cx({'z-10 flex justify-center items-center absolute bottom-0 top-0 left-0 right-0 bg-black/50': hasOverlay}, className, {
            "min-w-[100vw] min-h-[100vh] flex justify-center items-center": fullScreen
        })}>
            <div className="loading">
                <img src="/imgs/loading.gif" alt="loading"/>
            </div>
        </div>
    );
}