import React, {useCallback, useEffect, useState} from "react";
import {login} from "../services/authService";
import * as constants from "../CONSTANTS";
import {useCookies} from "react-cookie";
import hostnameAsSubDomain from "../utils/hostnameAsSubDomain";
import {unscrambleEmail} from "@/utils/protectEmail";
import {UI_ADMIN_ACCESS} from "@/authorizationActionsConstants";
import {ROOT} from "@/authorizationRolesConstants";

export const AuthContext = React.createContext<any>(null!);

interface UserCredentialsProps {
    email: string;
    password: string;
}

interface UseAuthProps {
    authToken: string;
    signIn: (userCredentials: UserCredentialsProps, callback: (error?: string) => void) => Promise<void>;
    signOut: (callback?: () => void) => void;
    userCan: (action: string) => boolean;
    isValidToken: () => boolean;
    user: {
        username: string;
        uuid: string;
        actions: string[];
        e?: string;
    },
    isAdmin: boolean;
}

export function useAuth(): UseAuthProps {
    return React.useContext(AuthContext);
}

function translateErrorMessage(error: string) {
    switch (String(error).toLowerCase()) {
        case 'forbidden':
            return 'Invalid login, please try again.';
        default:
            return error;
    }
}

const getUserFromToken = (authToken: string): Record<string, any> | false => {
    if (!authToken) return false;

    const userBase64 = String(authToken).split('.');

    if (!userBase64[1]) {
        return {};
    }

    return JSON.parse(atob(userBase64[1]));
}

const signIn = async (userCredentials: UserCredentialsProps, callback: (error?: string) => void, setAuthToken: (token: string) => void) => {
    try {
        const [error, authResponse] = await login(userCredentials);

        if (error) {
            const translatedErrorMessage = translateErrorMessage(error);
            typeof callback === 'function' && callback(translatedErrorMessage);
            return;
        }

        if (authResponse?.token) {
            typeof callback === 'function' && callback();
            setAuthToken(authResponse.token);
            return;
        }

        gtag('event', 'sign_in');

        typeof callback === 'function' && callback('Invalid login');
    } catch (error: any) {
        typeof callback === 'function' && callback(error.message);
    }
};

const signOut = (callback: VoidFunction, deleteCookie: any, setAuthToken: any): void => {
    deleteCookie(constants.AUTH_COOKIE);

    const domain = hostnameAsSubDomain();

    deleteCookie(constants.AUTH_COOKIE, {domain, path: '/'});

    setAuthToken(null!);

    gtag('event', 'sign_out');

    if (typeof callback === 'function') {
        return callback();
    }

    // for some reason this was keeping us from deleting the cookie, perhaps we
    // change pages too fast for the browser to check permission?
    setTimeout(() => {
        window.location.replace('/');
    }, 1000);
};

const isValidToken = (authToken) => {
    if (!authToken) return false;

    const user = getUserFromToken(authToken);

    if (!user) return false;

    const {exp} = user;

    if (Math.floor(Date.now() / 1000) < Number(exp)) {
        return true;
    }

    return false;
};

export default function AuthProvider(props: { children: React.ReactNode }) {
    const {children} = props;

    const [isAdmin, setIsAdmin] = useState<boolean>(false);

    const [cookies, , deleteCookie] = useCookies([constants.AUTH_COOKIE]);

    const [authToken, setAuthToken] = useState<string>();

    const [user, setUser] = useState<any>(null!);

    const userCan = useCallback((action: string) => {
        if (!authToken) return false;

        const user = getUserFromToken(authToken);

        if (!user) {
            console.log('No user found');
            return false;
        }

        if (!user?.actions) {
            console.log('No actions found for user');
            return false;
        }

        const userPermissions = Object.values(user.actions);

        return userPermissions.includes(action) || userPermissions.includes('ROOT');
    }, [authToken]);

    useEffect(() => {
        const isAdminResponse = userCan(UI_ADMIN_ACCESS) || userCan(ROOT);

        setIsAdmin(isAdminResponse);
    }, [authToken]);

    useEffect(() => {
        setAuthToken(() => cookies.auth);
    }, [cookies.auth]);

    useEffect(() => {
        if (!authToken) return;

        try {
            const foundUser = getUserFromToken(authToken);

            if (!foundUser) return;

            const email = unscrambleEmail(foundUser?.e || '');

            setUser({...foundUser, email});
        } catch (error: any) {
            console.debug(error?.message || error);
        }
    }, [authToken]);

    const value = {
        authToken,
        signIn: (userCredentials: UserCredentialsProps, callback: (error?: string) => void) => signIn(userCredentials, callback, setAuthToken),
        signOut: (callback: VoidFunction) => signOut(callback, deleteCookie, setAuthToken),
        userCan,
        isValidToken: useCallback(() => isValidToken(authToken), [authToken]),
        user,
        isAdmin
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
