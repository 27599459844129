import config from '../config';

enum Methods {
    'HEAD' = 'HEAD',
    'GET' = 'GET',
    'POST' = 'POST',
    'DELETE' = 'DELETE',
    'PUT' = 'PUT',
}

interface baseApiProps {
    path: string;
    method?: Methods;
    body?: any;
    authToken?: string;
    headers?: Record<string, any>;
}

export async function apiPut(path: string, body: any, authToken?: string): Promise<Response> {
    return baseApi({path, method: Methods.PUT, authToken, body})
}

export async function apiGet(path: string, authToken?: string): Promise<Response> {
    return baseApi({path, method: Methods.GET, authToken})
}

export async function apiPost(path: string, body?: any, authToken?: string, headers?: Record<string, any>): Promise<Response> {
    return baseApi({path, method: Methods.POST, body, authToken, headers})
}

export async function apiDelete(path: string, authToken?: string, headers?: Record<string, any>, body?: any): Promise<Response> {
    return baseApi({path, method: Methods.DELETE, authToken, headers, body})
}

// TODO this really is unstable. We use it everywhere but it's not really good. there are too many cases that could fail.
export async function extractPayloadFromResponse(response: Response, returnPayloadOnError = false): Promise<any> {
    if (!response.ok && response.status !== 400) {
        throw new Error(response.statusText);
    }

    if (!response.ok && response.status === 400) {
        const errorMessage = await response.text();

        throw new Error(errorMessage);
    }

    if (response.status === 200) {
        const payload = await response.json();

        if (payload?.error && !returnPayloadOnError) {
            throw new Error(payload.error);
        }

        return payload;
    }

    if (response.status === 204) {
        return response;
    }

    if (String(response.status).match(/2\d\d/) && response.status !== 200) {
        return response.status;
    }

    throw new Error(`Invalid payload ${response.status}`);
}

async function baseApi(props: baseApiProps): Promise<Response> {
    const {
        path,
        method = Methods.GET,
        body,
        authToken,
        headers: setHeaders
    } = props;

    const headers: Record<string, any> = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        ...setHeaders
    };

    if (authToken) {
        headers.Authorization = `Bearer ${authToken}`
    }

    Object.keys(headers).forEach((header) => {
        if (headers[header] === false) {
            delete headers[header]
        }
    })

    const url = new URL([config.apiBasePath.replace(/\/$/, ''), path.replace(/^\//, '').replace(/\/$/, '')].join('/'), config.apiUrl);

    return fetch(url.href, {
        method,
        headers,
        body
    });
}