import cx from "classnames";
import React, {ChangeEvent, forwardRef} from "react";

export interface InputProps {
    className: string;
    onChange: (event: ChangeEvent) => void;
    value: string;
    type: string;

    [key: string]: any;
}

const Input = forwardRef<any, any>(({className, onChange, type = 'text', value, defaultValue, ...rest}: InputProps, ref) => {
    return (
        <input onChange={onChange}
               value={value}
               defaultValue={defaultValue}
               type={type}
               ref={ref}
               className={cx("p-1 rounded-sm bg-gray-700", {'rangeSlider': type === 'range'}, {'input-checkbox': type === 'checkbox'}, className)} {...rest} />
    );
});

Input.displayName = 'Input';

export default Input;