import React, {Children} from "react";

export interface FooterNavProps {
    LinkAs: React.FC<any>;
    isAdmin?: boolean;
    [key: string]: any;
}

export default function FooterNav({isAdmin, LinkAs, ...rest}: FooterNavProps) {
    const Nav = ({children, ...rest}: any) => (
        <div {...rest}
             className="relative z-100 w-full relative flex flex-col justify-end content-end h-full mb-2">
            <ul className="list-none ml-0 flex flex-row gap-2 overflow-hidden overflow-stealth overflow-x-scroll max-w-full">
                {Children.map(children, (navItem: any) => <li className="text-white underline whitespace-nowrap">{navItem}</li>)}
            </ul>
        </div>
    );

    return (
        <Nav {...rest}>
            {
                isAdmin && <LinkAs href="/admin">Admin</LinkAs>
            }
            <LinkAs href="/logout">Logout</LinkAs>
            <LinkAs href="/self/profile">Home</LinkAs>
            <LinkAs href="/diary">Diary</LinkAs>
            <LinkAs href="/members">Members</LinkAs>
            <LinkAs href="/q">Lounge</LinkAs>
            <LinkAs href="/connects">Connects</LinkAs>
            <LinkAs href="/blog">Blog</LinkAs>
            <LinkAs href="/terms-and-conditions">Terms and Conditions</LinkAs>
            <LinkAs href="/site-training">Help</LinkAs>
        </Nav>
    );
}