import React, {useEffect} from 'react';
import type {AppProps} from 'next/app'
import '../assets/style/index.scss';
import Link from "next/link";
import {getCookie} from "@/utils/cookie";
import isValidToken from "@/utils/auth";
import AuthProvider from "@/providers/AuthProvider";
import SiteMessageProvider from "@/providers/SiteMessageProvider";
import SetupLayout from "@/components/SetupLayout";
import Script from "next/script";
import UserAccountProvider from "@/providers/UserAccountProvider";
import ProfileProvider from "@/providers/ProfileProvider";
import Head from "next/head";
import SiteSettingsProvider from "@/providers/SiteSettingsProvider";
import MaintenanceModeConsumer from "@/components/MaintenanceModeConsumer";
import RequireAuth from "@/components/RequireAuth";

const allowedSitePaths = [
    function():boolean {
        return Boolean(window.location.pathname === '/'
            || window.location.pathname.startsWith('/login')
            || window.location.pathname.startsWith('/signup')
            || window.location.pathname.startsWith('/forgot-password')
            || window.location.pathname.startsWith('/reset-password')
            || window.location.pathname.startsWith('/admin/maintenance')
            || window.location.pathname.startsWith('/blog')
            || window.location.pathname.startsWith('/diary/public')
            || window.location.pathname.match('/q/.*')
            || window.location.pathname.startsWith('/q/code')
            || window.location.pathname.startsWith('/terms-and-conditions'));
    }
];

export default function App({Component, pageProps}: AppProps) {
    const {showFooter, showHeader, allowFullScreen, pageClassName} = pageProps;

    return (
        <div id="root" className={
            pageClassName
        }>
            <Head>
                <title>Myweed.club - An online social cannabis club</title>
                <meta name="description"
                      content="Myweed.club is an online social cannabis club. We are a community of cannabis enthusiasts who enjoy sharing our experiences and knowledge with each other. You will received a free online weed diary when you signup."/>
                <meta name="keywords"
                      content="cannabis, weed, marijuana, social, club, online, diary, journal, community, cannabis community, cannabis club, cannabis diary, cannabis journal, cannabis social club, cannabis social network, cannabis social media"/>

                {/* General favicons */}
                <link rel="icon" href="/favicon-32x32.png" sizes="32x32"/>
                <link rel="icon" href="/favicon-16x16.png" sizes="16x16"/>

                {/* Apple Touch Icons */}
                <link rel="apple-touch-icon" sizes="57x57" href="/apple-touch-icon-57x57.png"/>
                <link rel="apple-touch-icon" sizes="72x72" href="/apple-touch-icon-72x72.png"/>
                <link rel="apple-touch-icon" sizes="76x76" href="/apple-touch-icon-76x76.png"/>
                <link rel="apple-touch-icon" sizes="114x114" href="/apple-touch-icon-114x114.png"/>
                <link rel="apple-touch-icon" sizes="120x120" href="/apple-touch-icon-120x120.png"/>
                <link rel="apple-touch-icon" sizes="144x144" href="/apple-touch-icon-144x144.png"/>
                <link rel="apple-touch-icon" sizes="152x152" href="/apple-touch-icon-152x152.png"/>
                <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon-180x180.png"/>
                <link rel="apple-touch-icon" href="/apple-touch-icon.png"/>

                {/* Android/Chrome favicons */}
                <link rel="icon" type="image/png" sizes="192x192" href="/android-chrome-192x192.png"/>
                <link rel="icon" type="image/png" sizes="256x256" href="/android-chrome-256x256.png"/>

                {/* Safari Pinned Tab */}
                <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5"/>

                {/* Web Manifest */}
                <link rel="manifest" href="/site.webmanifest"/>

                <meta property="og:url" content="https://myweed.club"/>
                <meta property="og:type" content="website"/>
                <meta property="og:title" content="Myweed.club - An online social cannabis club"/>
                <meta property="og:description"
                      content="Myweed.club is an online social cannabis club. We are a community of cannabis enthusiasts who enjoy sharing our experiences and knowledge with each other. You will received a free online weed diary when you signup."/>
                <meta property="og:image" content="https://myweed.club/apple-touch-icon-180x180.png"/>

                <meta name="twitter:card" content="summary_large_image"/>
                <meta property="twitter:domain" content="myweed.club"/>
                <meta property="twitter:url" content="https://myweed.club"/>
                <meta name="twitter:title" content="Myweed.club - An online social cannabis club"/>
                <meta name="twitter:description"
                      content="Myweed.club is an online social cannabis club. We are a community of cannabis enthusiasts who enjoy sharing our experiences and knowledge with each other. You will received a free online weed diary when you signup."/>
                <meta name="twitter:image" content="https://myweed.club/apple-touch-icon-180x180.png"/>
            </Head>
            <Script src="https://www.googletagmanager.com/gtag/js?id=G-HL98HC96TW"/>
            <Script id="google-analytics">
                {`
                      window.dataLayer = window.dataLayer || [];
                      function gtag(){dataLayer.push(arguments);}
                      gtag('js', new Date());
             
                      gtag('config', 'G-HL98HC96TW');
                    `}
            </Script>
            <AuthProvider>
                <SiteSettingsProvider>
                    <MaintenanceModeConsumer>
                        <UserAccountProvider>
                            <ProfileProvider>
                                <SiteMessageProvider>
                                    <RequireAuth byPassIfTrue={allowedSitePaths}>
                                        <SetupLayout allowFullScreen={allowFullScreen}
                                                     showHeader={showHeader} showFooter={showFooter}
                                                     Link={Link} component={Component}
                                                     pageProps={pageProps}/>
                                    </RequireAuth>
                                </SiteMessageProvider>
                            </ProfileProvider>
                        </UserAccountProvider>
                    </MaintenanceModeConsumer>
                </SiteSettingsProvider>
            </AuthProvider>
        </div>
    )
}
