import React, {useState} from "react";

export const ProfileModalContext = React.createContext<any>({
    isLoading: false,
    setIsLoading: () => {},
    selectedProfileImageToUpload: null,
    setSelectedProfileImageToUpload: () => {},
    isModalOpen: false,
    setIsModalOpen: () => {}
});

export function useProfilePage() {
    return React.useContext(ProfileModalContext);
}

export default function ProfileProvider({ children }: { children: React.ReactNode }) {
    const [selectedProfileImageToUpload, setSelectedProfileImageToUpload] = useState<any>();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const setterGetters = {
        isLoading,
        setIsLoading,
        selectedProfileImageToUpload,
        setSelectedProfileImageToUpload,
        isModalOpen,
        setIsModalOpen
    };

    return <ProfileModalContext.Provider value={setterGetters}>{children}</ProfileModalContext.Provider>;
}
