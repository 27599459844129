import {Dispatch, SetStateAction} from "react";
import {ChatEntryInterface} from "../Chat";

export default function initialMessageStateReceivedHandler(setMessages: SetStateAction<any>, autoScrollHandler: () => void, setIsLoading: Dispatch<SetStateAction<boolean>>) {
    return (event: Event) => {
        const {detail: {chatEntries}} = event as CustomEvent<{chatEntries: ChatEntryInterface[]}>;

        setMessages(chatEntries);

        setIsLoading(false);

        setTimeout(() => {
            autoScrollHandler();
        }, 100);
    };
}