import React, {Children, useEffect, useState} from "react";
export interface MainNavProps {
    LinkAs: any;

    [key: string]: any;
}

export default function MainNav({LinkAs, ...rest}: MainNavProps) {
    if (typeof LinkAs === 'undefined') {
        return null;
    }

    const Nav = ({children, ...rest}: any) => (
        <ul className="flex w-full ml-0 p-10 animate-top justify-end flex-col">
            {Children.map(children, (navItem: any) => <li
                className="flex whitespace-nowrap cursor-pointer text-xl">{navItem}</li>)}
        </ul>
    );

    return (
        <Nav {...rest}>
            <LinkAs className="py-2 no-underline text-black w-full text-end cursor-pointer"
                    href="/self/profile">Home</LinkAs>
            <LinkAs className="py-2 no-underline text-black w-full text-end cursor-pointer"
                    href="/diary">Diary</LinkAs>
            <LinkAs className="py-2 no-underline text-black w-full text-end cursor-pointer"
                    href="/members">Members</LinkAs>
            <LinkAs className="py-2 no-underline text-black w-full text-end cursor-pointer" href="/q">Lounge</LinkAs>
            <LinkAs className="py-2 no-underline text-black w-full text-end cursor-pointer"
                    href="/events">Events</LinkAs>
            <LinkAs className="py-2 no-underline text-black w-full text-end cursor-pointer"
                    href="/connects">Connects</LinkAs>
            <LinkAs className="py-2 no-underline text-black w-full text-end cursor-pointer"
                    href="/blog">Blog</LinkAs>
        </Nav>
    );
}