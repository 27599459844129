import React, {ReactElement, useState} from 'react';
import {useAuth} from "../providers/AuthProvider";
import cx from "classnames";

export interface ChatMountProps {
    children: ReactElement;
    isChatOpen?: boolean;
}

export default function ChatMount({children, isChatOpen = false}: ChatMountProps) {
    const {isValidToken} = useAuth();

    const [showChat, setShowChat] = useState(isChatOpen);

    if (!isValidToken()) {
        return null;
    }

    return (
        <div className="right-4 bottom-0 drop-shadow fixed max-h-[90%] z-[199999]">
            <div className="w-[300px] max-h-[800px] border border-t-2 border-l-2 border-r-2 border-black rounded">
                <div className="flex w-full flex-1 flex-row">
                    <div
                        id="chat-toggle"
                        className="flex-1 cursor-pointer bg-tertiary px-2 rounded-tl rounded-tr text-black justify-center items-center flex"
                        onClick={() => setShowChat(!showChat)}>{showChat ? 'Close' : 'Open'} Live Chat
                    </div>
                </div>
                <div className="w-full border-l-2 border-r-2 border-top-2 border-primary bg-black">
                    <div className={cx({'hidden': !showChat})}>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
}