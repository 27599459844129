import {io, Socket} from "socket.io-client";
import cookies from "js-cookie";
import {AUTH_COOKIE} from "../CONSTANTS";

const autoConnect = true;

let socket: Socket;

try {
    socket = io(process.env.NODE_ENV === 'production' ? 'https://api.myweed.club' : `${window.location.protocol}//${window.location.hostname}:3013`, {
        forceNew: false,
        withCredentials: true,
        autoConnect,
        auth: cb => {
            const authToken: string = String(cookies.get(AUTH_COOKIE));

            if (authToken) {
                cb({token: authToken});
                return;
            }

            throw new Error('Invalid Token');
        }
    });
} catch(error: any) {
   console.log(error.message);
}

export {
    socket,
    autoConnect
}