import React, {forwardRef} from "react";
import ChatMessages from "../../components/ChatMessages/ChatMessages";
import ChatMessage from "../../components/ChatMessage/ChatMessage";
import {ChatEntryInterface} from "./Chat";

export interface MessagesProps {
    messages: ChatEntryInterface[];
    className?: string;
    isLoading?: boolean;
}

function Messages({messages = [], className, isLoading}: MessagesProps, ref) {
    if (isLoading) {
        return <ChatMessages ref={ref} className={className}><p>Loading...</p></ChatMessages>;
    }

    if (!Array.isArray(messages)) {
        return <ChatMessages ref={ref} className={className}><p>&nbsp;</p></ChatMessages>;
    }

    return (
        <ChatMessages ref={ref} className={className}>
            {messages.map((message, key) => <ChatMessage key={key} chatEntry={message}/>)}
        </ChatMessages>
    );
}

export default forwardRef(Messages);

