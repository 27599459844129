import React, {ReactElement, ReactNode, Suspense, useEffect, useMemo, useState} from "react";
import MainNav from "@/layout/nav/MainNav";
import HomeNav from "@/layout/nav/HomeNav";
import {Header} from "@/layout/Header";
import Body from "@/layout/Body";
import Footer from "@/layout/Footer/Footer";
import SiteMessage from "@/components/SiteMessage/SiteMessage";
import Loading from "@/components/Loading/Loading";
import Chat from "@/components/Chat/Chat";
import ChatMount from "@/layout/ChatMount";
import cx from "classnames";
import {ErrorBoundary} from "@/components/ErrorBoundary";
import {getCookie} from "@/utils/cookie";
import isValidToken from "@/utils/auth";

// Update the THEME to the theme you want to use
export const THEME = 'green';

const SHOW_BANNER = false;

// then goto both src/assets/style/index.scss & THEME_COLORS.js

export interface DefaultLayoutProps {
    children: ReactElement;
    LinkAs: any;
    allowFullScreen?: boolean;
    showHeader?: boolean;
    showFooter?: boolean;
    showFooterNav?: boolean;
    isAdmin?: boolean;
    extend?: ReactNode;
}

export default function DefaultLayout(props: DefaultLayoutProps) {
    const {
        allowFullScreen = false,
        children,
        showHeader = true,
        showFooter = true,
        showFooterNav = true,
        LinkAs,
        isAdmin = false,
        extend
    } = props;

    const [showMenu, setShowMenu] = useState<boolean>(true);

    useEffect(() => {
        const authToken = getCookie('auth');

        if (!isValidToken(authToken)) {
            setShowMenu(false);
        }
    }, []);

    const menu = useMemo(() => {
        return showMenu ? <MainNav LinkAs={LinkAs}/> : <HomeNav LinkAs={LinkAs}/>
    }, [showMenu, LinkAs]);

    const content = useMemo(() => {
        return (
            <div className="bg-black/90 w-full">
                {showHeader && <Header menu={menu} className="top-0 w-full z-[1001] mb-4 shadow-xl"/>}
                <div className="relative z-[1000] h-min-[100vh]">
                    <Body className="z-[1002] mb-4">
                        {children}
                    </Body>
                </div>
                {showFooter && <Footer LinkAs={LinkAs} showFooterNav={showFooterNav} isAdmin={isAdmin}/>}
            </div>
        );
    }, [showFooter, showFooterNav, isAdmin, children, menu, LinkAs]);

    return (
        <ErrorBoundary>
            <div className="overflow-x-hidden w-full max-w-full">
                <div
                    style={{
                        backgroundImage: `url(/imgs/themes/${THEME}/website-splash.jpg)`
                    }}
                    className={`w-full bg-no-repeat bg-cover fixed left-0 top-0 right-0 bottom-0`}/>
                <SiteMessage/>
                <div className="relative flex flex-row min-h-[100vh] max-w-full">
                    <div
                        className={cx("hidden xl:flex flex-[0] xl:flex-[2]", {
                            "!hidden": allowFullScreen,
                        })}>
                        &nbsp;
                    </div>
                    <div
                        className={cx("relative z-[10] p-0 flex flex-col  min-w-[380px] overflow-hidden w-full flex-[12] md:border-x-[10px] lg:border-x-[10px] xl:border-x-[10px] border-black", {
                            "border-none": allowFullScreen,
                            "min-w-[100vw]": allowFullScreen,
                            "max-w-[600px]": !allowFullScreen,
                        })}>
                        {
                            SHOW_BANNER
                            && (
                                <div className="bg-white flex flex-row items-center justify-start">
                                    <div className="flex-1">
                                        <img onError={function (event) {
                                            if (!event?.target) return;

                                            const target = event.target as HTMLImageElement;

                                            target.style.display = 'none';
                                        }} src={`/imgs/themes/${THEME || 'green'}/banner.png`}
                                             className="p-2 mx-auto max-h-[80px]"/>
                                    </div>
                                    <p className="flex-[3] text-black p-2"><a
                                        target="_blank" className="text-black underline text-2xl"
                                        href="https://www.exhalesociety.com/membership">Snag your passes to the Exhale Society</a>
                                    </p>
                                </div>
                            )
                        }
                        <div className="min-h-[100vh] w-full bg-black/90 max-w-full overflow-x-hidden">
                            {content}
                        </div>
                    </div>
                    <div className={cx("relative z-[9] overflow-hidden hidden sm:flex xl:flex-[20]", {
                        "!hidden": allowFullScreen,
                    })}>
                        <div className="w-full">
                            {SHOW_BANNER &&
                                <img src={`/imgs/themes/${THEME || 'green'}/banner.png`} className="w-auto"/>}
                        </div>
                    </div>
                </div>
                {extend}
                <ChatMount>
                    <Suspense fallback={<Loading/>}>
                        <Chat/>
                    </Suspense>
                </ChatMount>
            </div>
        </ErrorBoundary>
    );
}