import {ReactElement} from "react";

export interface FriendlyTimeStampProps {
    date: string | Date;
}

export default function FriendlyTimeStamp({date}: FriendlyTimeStampProps): ReactElement | null {
    const receivedDate = new Intl.DateTimeFormat(navigator.language,
        {
            timeStyle: "medium",
            dateStyle: "short"
        });

    try {
        return <>{receivedDate.format(new Date(date))}</>;
    } catch(error: any) {
        console.error(error.message);
        return null;
    }
}