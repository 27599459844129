import React, {useRef, useState} from "react";
import {useSiteMessage} from "../../providers/SiteMessageProvider";
import Loading from "../../components/Loading/Loading";
import FileInput from "../../components/FileInput/FileInput";
import setImageOnUpload from "../../utils/setImageOnUpload";
import {apiPost} from "../../services/apiService";
import {SiteMessageEnum} from "@varymadellc/my-weed-club-component-library";
import {useAuth} from "../../providers/AuthProvider";
import ImageUploadEditor from "../../components/ImageUploadEditor";
import {useChatSocketProvider} from "../../providers/ChatSocketProvider";
import {imageToDataUri} from "../../services/imageUtilService";

export default function ChatModal({setIsOpen}: { setIsOpen: (bool: boolean) => void }) {
    const {socket} = useChatSocketProvider();

    const [chatImage, setChatImage] = useState<{ image?: any, name?: string } | null>(null);

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const {authToken} = useAuth();

    const {setMessage} = useSiteMessage();

    const setNewImage = async ({image, name}: any) => {
        try {
            setIsLoading(true);

            console.time('chat Image resize');

            const controlledImage = document.createElement('img');

            controlledImage.addEventListener('load', async () => {
                const resizedImageData = await imageToDataUri(controlledImage, 1000, 1000);

                if (resizedImageData) {
                    console.timeEnd('chat Image resize');

                    setChatImage({image: resizedImageData, name});

                    setIsLoading(false);

                    return;
                }

                setMessage('Failed to upload image');
            })

            controlledImage.addEventListener('error', () => {
                setMessage('Failed to upload image');

                setIsLoading(false);
            });

            const fileReader = new FileReader();

            fileReader.addEventListener('load', () => {
                controlledImage.src = fileReader.result as string;
            });

            fileReader.readAsDataURL(image);

            setIsLoading(false);
        } catch (error: any) {
            setMessage(error.message);
        }
    }

    const fileRef = useRef<any>();

    const reset = () => {
        setMessage(null);
        setChatImage(null);

        if (fileRef.current) {
            fileRef.current.value = null;
        }

        setIsOpen(false);
    };

    const onUpload = (image: any, name: string) => async () => {
        setIsLoading(true);

        const imageData = await fetch(image);

        const blob = await imageData.blob();

        const formData = new FormData();
        formData.append('name', 'payload');

        formData.append('file', blob, name);

        try {
            const response = await apiPost('/upload?type=chat-image', formData, authToken, {'Content-Type': false});

            if (response.ok) {
                const file = await response.json();

                if (file.error) {
                    setMessage(file.error, SiteMessageEnum.error);
                    setIsLoading(false);

                    return;
                }

                if (!socket.connected) {
                    await socket.connect();
                }

                await socket.emit('chatImage', file.filePathOrUrl)

                reset();

                setIsLoading(false);

                setMessage('Image posted');
            } else {
                setMessage('Failed at this time', SiteMessageEnum.error);
                setIsLoading(false);
            }
        } catch (error) {
            console.error(error);
            setMessage('Unable to upload your imageData at this time.', SiteMessageEnum.error);
        }
    };

    return (
        <div className="mx-auto flex flex-col">
            <h2>Upload Image</h2>
            <div className="relative flex flex-col p-2">
                <FileInput name="profileImage"
                           type="file"
                            accept="image/*"
                           _ref={fileRef}
                           onChange={setImageOnUpload(setNewImage)}/>
                {isLoading && <Loading hasOverlay={true}/>}
                {
                    chatImage &&
                    <ImageUploadEditor
                        rounded={false}
                        image={chatImage}
                        onReset={reset}
                        onUpload={onUpload}
                        setIsLoading={setIsLoading}
                    />
                }
            </div>
        </div>
    );
}