import {useLayoutEffect} from "react";
import Input from "../Input/Input";
import cx from "classnames";

export interface FileInputProps {
    _ref?: any;
    className?: string;
    [arg: string]: any;
}

export default function FileInput({_ref: ref, src, className, ...rest}: FileInputProps) {
    useLayoutEffect(() => {
        if (ref?.current) {
            ref.current.reset = function() {
                ref.current.value = null;
            };

            ref.current.onchange = function () {
                if (!this.value) {

                    return;
                }
            }
        }
    });

    return (
        <label className={cx("mx-auto w-full height-[40px] overflow-hidden text-white max-w-full", className)}>
            <Input className="file:mr-4 file:py-2 file:px-4
                              max-w-[90%]
                              file:rounded-sm file:border-0
                              file:text-sm file:font-semibold
                              file:bg-primary file:text-black" {...rest as any} type="file" src={src} ref={ref}/>
        </label>
    );
}