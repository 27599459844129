import {ReactNode} from "react";

export interface MaintenanceModeProps {
    children: ReactNode;
    isAdmin?: boolean;
}

export default function MaintenanceMode({isAdmin = false, children}: MaintenanceModeProps) {
    if (isAdmin) {
        return (
            <div className="border-4 border-red-500">
                <div className="fixed left-0 top-0 bg-red-500 z-[1000]">Maintenance Mode</div>
                {children}
            </div>
        )
    }

    return (
        <div className="relative flex flex-col items-center justify-center w-full h-full p-4 max-w-[800px] mx-auto">
            <img src="/imgs/logo.png" alt="Maintenance Icon" className="maintenance-icon"/>
            <br/>
            <h1 className="text-xl">We&apos;re Making It Even Better!</h1>
            <br/>
            <p className="m-2">
                We&apos;re currently performing upgrades and improvements to our website to enhance your
                browsing
                experience.
                Embrace the excitement of positive change, as we work diligently to bring you a more
                seamless and feature-rich platform.
            </p>
            <p className="m-2">
                Our team is committed to delivering the best user experience possible, and we appreciate
                your patience and understanding
                during this maintenance period.
            </p>
            <p className="m-2">
                The upgrades won&apos;t take long, and we&apos;ll be back before you know it. In the meantime,
                if you
                have any questions or
                need assistance, feel free to reach out to us at <a href="mailto:admin@myweed.club">admin@MyWeed.Club</a>.
            </p>
            <p className="m-2">
                Thank you for being a part of our community. We can&apos;t wait to reveal the new and improved
                site changes to you soon!
            </p>
        </div>
    );
}