import React from "react";
import cx from "classnames";
import FooterNav from "@/layout/nav/FooterNav";
import Image from "next/image";

export interface FooterProps {
    LinkAs: React.FC<any>;
    className?: string;
    isAdmin?: boolean;
    showFooterNav?: boolean;
}

export default function Footer({className, isAdmin, LinkAs, showFooterNav = true}: FooterProps) {
    return (
        <footer className={cx("app-footer relative z-100 bg-no-repeat min-h-[120px] bg-[url('/imgs/footer-bg.png')]", className)}>
            <div className="p-4 top-0 left-0">
                {showFooterNav && <FooterNav LinkAs={LinkAs} isAdmin={isAdmin}/>}
                <div>
                    Myweed.club © 2021 - {(new Date).getFullYear()}
                </div>
            </div>
        </footer>
    );
}