import {SetStateAction} from "react";
import {ChatEntryInterface} from "../Chat";

export default function chatImageReceivedHandler(setMessages: SetStateAction<any>, autoScrollHandler: () => void) {
    return (event: Event) => {
        const {detail: {username, chatEntry}} = event as CustomEvent<any>;

        setMessages((messages: ChatEntryInterface[]) => [...messages, {...chatEntry, senderUsername: username}])

        autoScrollHandler();
    };
}