import cx from "classnames";
import React, {ReactNode, useEffect, useRef} from "react";
import {useRouter} from "next/router";
import Link from "next/link";
import {useAuth} from "@/providers/AuthProvider";
import {THEME} from "@/layout/DefaultLayout/DefaultLayout";

export interface HeaderProps {
    menu?: ReactNode;
    className?: string;
}

export default function Header({menu, className}: HeaderProps) {
    const {authToken, isValidToken} = useAuth();

    const router = useRouter();

    const [isChatOpen, setIsChatOpen] = React.useState(false);

    const menuRef = React.useRef<HTMLDivElement>(null);

    const [menuOpen, setMenuOpen] = React.useState(false);

    const toggleMenu = () => setMenuOpen(!menuOpen);

    const [showChatIcon, setShowChatIcon] = React.useState(false);

    useEffect(() => {
        setShowChatIcon(isValidToken() && authToken !== null);
    }, [authToken]);

    useEffect(() => {
        if (!menuRef.current) return;
        menuRef.current.onclick = toggleMenu;
        menuRef.current.style.display = menuOpen ? 'block' : 'none';
    }, [menuOpen, menuRef.current]);

    useEffect(() => {
        document.addEventListener('keyup', (event) => {
            if (event.key === 'Escape') {
                setMenuOpen(false);
            }
        });
    }, []);

    return (
        <div className={cx("relative w-full max-w-full", className)}>
            <div ref={menuRef} className={cx("w-full absolute z-0 bg-primary text-black flex flex-col hidden")}>
                <div className="relative mt-[60px] text-black">
                    {menu}
                </div>
            </div>
            <div className="flex flex-row w-full relative z-1 gap-0 m-0 h-[60px]">
                <div
                    onClick={() => router.push('/')}
                    style={{backgroundImage: `url(/imgs/themes/${THEME || 'green'}/bg@2x.png)`}}
                    className={`flex w-full items-center bg-contain bg-repeat-x relative min-w-[120px] sm:min-w-[160px] max-w-[240px] overflow-x-hidden`}>
                    <Link href="/" className="relative z-[10] w-full h-full">
                        <img src="/imgs/full-logo.png" alt="logo"
                             className="absolute left-0 top-[50%] -translate-y-[50%] w-[200px] p-2 hidden sm:block md:block lg:block xl:block"/>
                        <img src="/imgs/icon.png" alt="logo"
                             className="absolute left-0 max-h-full w-auto p-2 block sm:hidden md:hidden lg:hidden xl:hidden"/>
                    </Link>
                </div>
                <div
                    className="flex w-full items-center bg-contain bg-repeat-x relative"
                    style={{backgroundImage: `url(/imgs/themes/${THEME || 'green'}/bg@2x.png)`}}>
                    &nbsp;
                </div>
                <img className="m-0 w-[60px]"
                     src={`/imgs/themes/${THEME || 'green'}/left@2x.png`} alt="middle"/>
                <div
                    style={{backgroundImage: `url(/imgs/themes/${THEME || 'green'}/middle@2x.png)`}}
                    className={`min-w-[100px] bg-repeat-x max-h-full relative bg-contain w-full max-w-[200px]`}>
                    <div onClick={toggleMenu}
                         className="flex absolute top-0 left-0 right-0 bottom-0 justify-center items-center">
                        <span
                            className="text-black text-xl sm:ml-4 mt-4 whitespace-nowrap">{!menuOpen ? 'Menu' : 'Close Menu'}</span>
                    </div>
                </div>
                <img src={`/imgs/themes/${THEME || 'green'}/right@2x.png`} className="w-full max-w-[66px]"/>
            </div>
        </div>
    );
}