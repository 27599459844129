import {useSiteSettings} from "@/providers/SiteSettingsProvider";
import {ReactElement, ReactNode} from "react";
import {useAuth} from "@/providers/AuthProvider";
import MaintenanceMode from "@/components/MaintenanceMode";
import {useRouter} from "next/router";

export interface MaintenanceModeConsumerProps {
    children: ReactElement;
}

export default function MaintenanceModeConsumer({children}: MaintenanceModeConsumerProps) {
    const {isAdmin} = useAuth();

    const router = useRouter();

    const {bypass} = router.query;

    const [settings] = useSiteSettings();

    if (!settings) {
        return children;
    }

    const {isMaintenanceMode} = settings;

    return (
        <>
            {(String(isMaintenanceMode) === 'true' && !bypass)
                ?
                <MaintenanceMode isAdmin={isAdmin}>{children}</MaintenanceMode>
                :
                children}
        </>
    );
}