import {ChatEntryInterface} from "../../components/Chat/Chat";
import React from "react";
import ChatMessageTypeMessage from "../ChatMessage/ChatMessageTypeMessage";
import ChatMessageTypeAudio from "../ChatMessage/ChatMessageTypeAudio";
import FriendlyTimeStamp from "../FriendlyTimeStamp/FriendlyTimeStamp";
import ChatMessageTypeImage from "../ChatMessage/ChatMessageTypeImage";

export interface ChatEntryProps {
    chatEntry: ChatEntryInterface;
}

export default function ChatEntry({chatEntry}: ChatEntryProps) {
    const {
        senderUsername = 'whoKNows',
        createdAt,
    } = chatEntry;

    const getMessageElement = () => {
        switch (chatEntry.messageType) {
            case 'image':
                return <ChatMessageTypeImage chatEntry={chatEntry}/>;
            case 'audio':
                return <ChatMessageTypeAudio chatEntry={chatEntry}/>;
            case 'message':
            default:
                return <ChatMessageTypeMessage chatEntry={chatEntry}/>;
        }
    };

    return (
        <div className="flex flex-col border-b border-b-gray-800 p-2 max-w-full">
            <div className="flex flex-col">
                <div className="pb-2 flex-grow-1 small text-white font-bold h2 flex-1 break-words">{senderUsername}</div>
                <div className="flex text-end text-[10px]"><FriendlyTimeStamp date={createdAt}/></div>
            </div>
            <span className="text-base">{getMessageElement()}</span>
        </div>
    );
}