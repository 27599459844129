import React, {ReactElement, ReactNode, useEffect} from "react";
import {FiX} from "react-icons/fi";
import {SiteMessageType} from "@/types";
import {useSiteMessage} from "@/providers/SiteMessageProvider";

export enum SiteMessageEnum {
    info,
    error
}

interface SiteMessageProps {
}

type MessageType = SiteMessageType['message'];

export default function SiteMessage({}: SiteMessageProps) {
    const {message, setMessage} = useSiteMessage();

    useEffect(() => {
        if (!message?.message) return;

        setTimeout(() => {
            setMessage(null);
        }, 8000);
    }, [message, setMessage]);

    if (!message?.message) return null;

    return (
        <div className="flex justify-center z-[1000001] fixed bottom-0 left-0 right-0">
            <div onClick={() => setMessage(null)} className={`p-4 border border-black text-black rounded m-2 bg-tertiary`} role="alert">
                <div className="flex flex-row relative">
                    <div className="flex-grow-1 p-2 !text-black">
                        {message.message}
                    </div>
                    <div className="absolute -right-[20px] -top-[20px] flex-grow-0 m-2">
                        <FiX onClick={() => setMessage(null)} className="w-[30px] h-[30px]"/>
                    </div>
                </div>
            </div>
        </div>
    );
}