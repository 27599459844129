import React from "react";

export interface ChatStatsProps {
    username: string;
    activeUsersCount: number;
    messages: any[];
}

export default function ChatStats({username, activeUsersCount, messages}: ChatStatsProps) {
    return (
        <div className="flex flex-row flex-wrap gap-2">
            <p><strong>Your username is</strong>: {username}</p>
            <p><strong>Message Count</strong>: {messages.length}</p>
            <p><strong>Active Users</strong>: {activeUsersCount}</p>
        </div>
    );
}