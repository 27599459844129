import React, {useCallback, useMemo, useState} from "react";
import {SiteMessageEnum} from "@varymadellc/my-weed-club-component-library";
import {SiteMessageType} from "@/types";

const falseSetMessage = (message: string, type: SiteMessageEnum) => {}

export const SiteMessageContext = React.createContext<any>({message: '', setMessage: falseSetMessage});

interface useSiteMessageProps {
    message: SiteMessageType;
    setMessage: any;
}

export function useSiteMessage(): useSiteMessageProps {
    return React.useContext(SiteMessageContext);
}

export default function SiteMessageProvider({ children }: any) {
    const [message, setMessageState] = useState<{ message: string; type: SiteMessageEnum } | null>({
        message: '',
        type: SiteMessageEnum.info
    });

    const setMessage = useCallback((message, type = SiteMessageEnum.info) => {
        setMessageState({ message, type });
    }, []);

    const value = useMemo(() => {
        return {
            message,
            setMessage
        };
    }, [message]);

    return (
        <SiteMessageContext.Provider value={value}>
            {typeof children === 'function' ? children(value) : children}
        </SiteMessageContext.Provider>
    );
}
