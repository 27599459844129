export const UI_ADMIN_ACCESS = 'UI_ADMIN_ACCESS';

export const DELETE_RESOURCE = 'DELETE_RESOURCE';
export const CREATE_RESOURCE = 'CREATE_RESOURCE';
export const PUT_RESOURCE = 'PUT_RESOURCE';

export const POST_AUTHORIZATION_ACTION = 'POST_AUTHORIZATION_ACTION';
export const GET_AUTHORIZATION_ACTION = 'GET_AUTHORIZATION_ACTION';
export const DELETE_AUTHORIZATION_ACTION = 'DELETE_AUTHORIZATION_ACTION';

export const POST_AUTHORIZATION_ROLE = 'POST_AUTHORIZATION_ROLE';
export const GET_AUTHORIZATION_ROLE = 'GET_AUTHORIZATION_ROLE';
export const DELETE_AUTHORIZATION_ROLE = 'DELETE_AUTHORIZATION_ROLE';

export const GET_ACCOUNT_SEARCH = 'GET_ACCOUNT_SEARCH';

export const ASSIGN_ACTION_TO_ROLE = 'ASSIGN_ACTION_TO_ROLE';
export const UNASSIGN_ACTION_FROM_ROLE = 'UNASSIGN_ACTION_FROM_ROLE';
export const ASSIGN_ROLE_TO_ACCOUNT = 'ASSIGN_ROLE_TO_ACCOUNT';

export const GET_ACCOUNT = 'GET_ACCOUNT';
export const GET_ACCOUNTS = 'GET_ACCOUNTS';
export const DELETE_ACCOUNT = 'DELETE_ACCOUNT';

export const PUT_ACCOUNT_PASSWORD = 'PUT_ACCOUNT_PASSWORD';

export const GET_ACCOUNT_ROLE = 'GET_ACCOUNT_ROLE';
export const POST_ACCOUNT_ROLE = 'POST_ACCOUNT_ROLE';

export const GET_POST = 'GET_POST';
export const GET_POST_UNPUBLISHED = 'GET_POST_UNPUBLISHED';
export const GET_USER_UNPUBLISHED_POST = 'GET_USER_UNPUBLISHED_POST';
export const POST_POST = 'POST_POST';
export const DELETE_POST = 'DELETE_POST';
export const UPDATE_POST = 'UPDATE_POST';

export const ACCOUNT_SEARCH = 'ACCOUNT_SEARCH';

export const GET_DB_SYNC = 'GET_DB_SYNC';
export const GET_DB_TEST = 'GET_DB_TEST';

export const PASSWORD_GENERATE = 'PASSWORD_GENERATE';

export const CREATE_ACCOUNT = 'CREATE_ACCOUNT';

export const TEST = 'TEST';
export const EMAIL = 'EMAIL';

export const POST_UPLOAD = 'POST_UPLOAD';

export const GET_SELF_ACCOUNT = 'GET_SELF_ACCOUNT';
export const PUT_SELF_ACCOUNT = 'PUT_SELF_ACCOUNT';